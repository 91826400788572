<template>
  <b-container fluid>
    <h3 class="mt-3 mb-2">Informações pessoais</h3>
    <b-row>
      <b-col cols="3">
        <b-form-group>
          <label for="job">Profissão</label>
          <b-input id="job" v-model="form.job" placeholder="Descrever" />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <label for="birthday">Data de nascimento</label>
          <date-picker
            id="birthday"
            name="birthday"
            placeholder="Selecionar"
            v-model="form.birthday"
            format="DD/MM/YYYY"
            :clearable="false"
            :lang="langDatePicker"
            class="full"
          />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <label for="gender">Sexo</label>
          <multiselect
            id="gender"
            v-model="form.gender"
            :options="genderOptions"
            track-by="value"
            label="name"
            :option-height="40"
            :showLabels="false"
            :searchable="false"
            placeholder=""
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <label for="marital_status">Estado civil</label>
          <multiselect
            id="marital_status"
            v-model="form.marital_status"
            :options="maritalStatusOptions"
            :option-height="40"
            :showLabels="false"
            :searchable="false"
            placeholder=""
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group>
          <label for="religion">Religião </label>
          <multiselect
            v-model="form.religion"
            id="religion"
            :options="opcoesReligion"
            :option-height="40"
            :showLabels="false"
            :showNoResults="true"
            placeholder="Selecionar"
            class="with-border"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult" slot-scope="props">
              <li @click="setReligion(props.search)">
                <div class="multiselect__option custom-item">
                  <Plus class="icon" />
                  Adicionar "{{ props.search }}"
                </div>
              </li>
            </template>
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'ContactData',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    genderOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Plus: () => import('@/assets/icons/plus.svg')
  },
  data: () => ({
    langDatePicker: {
      formatLocale: {
        weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
      }
    },
    maritalStatusOptions: [
      'Solteiro(a)',
      'Casado(a)',
      'Divorciado(a)',
      'Viúvo(a)',
      'Separado(a)'
    ],
    opcoesReligion: ['Católico', 'Evangélico', 'Não declarado', 'Sem religião']
  }),
  methods: {
    setReligion(value) {
      if (!value.length) return
      this.opcoesReligion.push(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: var(--blue-500);
}
</style>
